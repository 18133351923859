import { ReactElement, useContext } from 'react';
import ErrorPage from 'next/error';

import Index from '.';
import { ModalsContext } from '@root/context/ModalsContext';

export default function Home(): ReactElement {
  const modalsContext = useContext(ModalsContext);

  // console.log(modalsContext.routeIsValid);

  if (!modalsContext) {
    return null;
  }

  if (modalsContext.routeIsValid) {
    return (
      <Index />
    );
  }

  return <ErrorPage statusCode={ 404 } />;
}
